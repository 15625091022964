var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"container",attrs:{"element-loading-text":"数据较多，拼命加载中..."}},[_c('breadcrumb',{attrs:{"BreadcrumbData":_vm.BreadcrumbData}}),_c('div',{staticClass:"line"},[_c('div',{staticClass:"chart",staticStyle:{"text-align":"left"}},[_c('span',{staticClass:"tit"},[_vm._v("1.是否有客服经历？（人数）")]),(_vm.flag)?_c('div',{staticClass:"pie"},[_c('pie-charts',{ref:"pieEcharts",attrs:{"pieName":'userPie1',"userCharts":'userCharts',"pieColor":['#1890ff', '#5ad71c', '#ff9e09', '#ff5456'],"pieData":[
            { value: _vm.pieData1['1年内客服经验'], name: '1年内客服经验' },
            { value: _vm.pieData1['1-3内年客服经验'], name: '1-3年客服经验' },
            { value: _vm.pieData1['3年以上客服经验'], name: '3年以上客服经验' },
            { value: _vm.pieData1['无'], name: '无客服经验' }
          ]}})],1):_vm._e()]),_c('div',{staticClass:"chart",staticStyle:{"text-align":"left"}},[_c('span',{staticClass:"tit"},[_vm._v("2. 之前有没有从事过电商行业相关岗位？（人数）")]),_c('div',{staticClass:"pie"},[(_vm.flag)?_c('pie-charts',{ref:"pieEcharts",attrs:{"pieName":'userPie2',"userCharts":'userCharts',"pieColor":['#1890ff', '#5ad71c', '#ff9e09', '#ff5456'],"pieData":[
            { value: _vm.pieData2['没有'], name: '没有' },
            { value: _vm.pieData2['有'], name: '有' }
          ]}}):_vm._e()],1)])]),_c('div',{staticClass:"line"},[_c('div',{staticClass:"chart",staticStyle:{"text-align":"left"}},[_c('span',{staticClass:"tit"},[_vm._v("3.哪些网购平台使用比较多？（人数）")]),(_vm.flag)?_c('bar-charts',{attrs:{"homeData":[
          _vm.barData3['淘宝'],
          _vm.barData3['京东'],
          _vm.barData3['拼多多'],
          _vm.barData3['抖音'],
          _vm.barData3['快手'],
          _vm.barData3['其他']
        ],"xData":['淘宝', '京东', '拼多多', '抖音', '快手', '其他'],"barMain":'userCharts3'}}):_vm._e()],1),_c('div',{staticClass:"chart",staticStyle:{"text-align":"left"}},[_c('span',{staticClass:"tit"},[_vm._v("4.对哪个电商类目感兴趣/熟悉？（人数）")]),(_vm.flag)?_c('bar-charts',{attrs:{"homeData":[
          _vm.barData4['数码'],
          _vm.barData4['女装'],
          _vm.barData4['日用品'],
          _vm.barData4['电器'],
          _vm.barData4['食品'],
          _vm.barData4['百货'],
          _vm.barData4['男装'],
          _vm.barData4['家居'],
          _vm.barData4['美妆'],
          _vm.barData4['户外运动']
        ],"xData":[
          '数码',
          '女装',
          '日用品',
          '电器',
          '食品',
          '百货',
          '男装',
          '家居',
          '美妆',
          '户外运动'
        ],"barMain":'userCharts4'}}):_vm._e()],1)]),_c('div',{staticClass:"line"},[_c('div',{staticClass:"chart",staticStyle:{"text-align":"left"}},[_c('span',{staticClass:"tit"},[_vm._v("5. 期望薪酬范围？（人数）")]),_c('div',{staticClass:"pie"},[(_vm.flag)?_c('pie-charts',{ref:"pieEcharts",attrs:{"pieName":'userPie5',"userCharts":'userCharts',"pieColor":[
            '#1890ff',
            '#34e7f9',
            '#58a55c',
            '#f2bd42',
            '#f09009',
            '#ff5456',
            '#5ad71c'
          ],"pieData":[
            { value: _vm.pieData5['4000以下'], name: '4000以下' },
            { value: _vm.pieData5['4000-6000'], name: '4000-6000' },
            { value: _vm.pieData5['6000-8000'], name: '6000-8000' },
            { value: _vm.pieData5['8000-10000'], name: '8000-10000' },
            { value: _vm.pieData5['10000-15000'], name: '10000-15000' },
            { value: _vm.pieData5['15000-20000'], name: '15000-20000' },
            { value: _vm.pieData5['20000以上'], name: '20000以上' }
          ]}}):_vm._e()],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }