<!-- 员工列表调查问卷图表 -->
<template>
  <div
    v-loading="loading"
    element-loading-text="数据较多，拼命加载中..."
    class="container"
  >
    <breadcrumb :BreadcrumbData="BreadcrumbData"></breadcrumb>
    <div class="line">
      <!-- 1 -->
      <div style="text-align: left;" class="chart">
        <span class="tit">1.是否有客服经历？（人数）</span>
        <div class="pie" v-if="flag">
          <!-- 饼图 -->
          <pie-charts
            ref="pieEcharts"
            :pieName="'userPie1'"
            :userCharts="'userCharts'"
            :pieColor="['#1890ff', '#5ad71c', '#ff9e09', '#ff5456']"
            :pieData="[
              { value: pieData1['1年内客服经验'], name: '1年内客服经验' },
              { value: pieData1['1-3内年客服经验'], name: '1-3年客服经验' },
              { value: pieData1['3年以上客服经验'], name: '3年以上客服经验' },
              { value: pieData1['无'], name: '无客服经验' }
            ]"
          ></pie-charts>
        </div>
      </div>
      <!-- 2 -->
      <div style="text-align: left;" class="chart">
        <span class="tit">2. 之前有没有从事过电商行业相关岗位？（人数）</span>
        <div class="pie">
          <!-- 饼图 -->
          <pie-charts
            v-if="flag"
            ref="pieEcharts"
            :pieName="'userPie2'"
            :userCharts="'userCharts'"
            :pieColor="['#1890ff', '#5ad71c', '#ff9e09', '#ff5456']"
            :pieData="[
              { value: pieData2['没有'], name: '没有' },
              { value: pieData2['有'], name: '有' }
            ]"
          ></pie-charts>
        </div>
      </div>
    </div>
    <div class="line">
      <!-- 3 -->
      <div style="text-align: left;" class="chart">
        <span class="tit">3.哪些网购平台使用比较多？（人数）</span>
        <!-- <div class="pie"> -->
        <!-- 柱形图 -->
        <bar-charts
          v-if="flag"
          :homeData="[
            barData3['淘宝'],
            barData3['京东'],
            barData3['拼多多'],
            barData3['抖音'],
            barData3['快手'],
            barData3['其他']
          ]"
          :xData="['淘宝', '京东', '拼多多', '抖音', '快手', '其他']"
          :barMain="'userCharts3'"
        ></bar-charts>
        <!-- </div> -->
      </div>
      <!-- 4 -->
      <div style="text-align: left;" class="chart">
        <span class="tit">4.对哪个电商类目感兴趣/熟悉？（人数）</span>
        <!-- <div class="pie"> -->
        <!-- 柱形图 -->
        <bar-charts
          v-if="flag"
          :homeData="[
            barData4['数码'],
            barData4['女装'],
            barData4['日用品'],
            barData4['电器'],
            barData4['食品'],
            barData4['百货'],
            barData4['男装'],
            barData4['家居'],
            barData4['美妆'],
            barData4['户外运动']
          ]"
          :xData="[
            '数码',
            '女装',
            '日用品',
            '电器',
            '食品',
            '百货',
            '男装',
            '家居',
            '美妆',
            '户外运动'
          ]"
          :barMain="'userCharts4'"
        ></bar-charts>
        <!-- </div> -->
      </div>
    </div>
    <div class="line">
      <!-- 5 -->
      <div style="text-align: left;" class="chart">
        <span class="tit">5. 期望薪酬范围？（人数）</span>
        <div class="pie">
          <!-- 饼图 -->
          <pie-charts
            v-if="flag"
            ref="pieEcharts"
            :pieName="'userPie5'"
            :userCharts="'userCharts'"
            :pieColor="[
              '#1890ff',
              '#34e7f9',
              '#58a55c',
              '#f2bd42',
              '#f09009',
              '#ff5456',
              '#5ad71c'
            ]"
            :pieData="[
              { value: pieData5['4000以下'], name: '4000以下' },
              { value: pieData5['4000-6000'], name: '4000-6000' },
              { value: pieData5['6000-8000'], name: '6000-8000' },
              { value: pieData5['8000-10000'], name: '8000-10000' },
              { value: pieData5['10000-15000'], name: '10000-15000' },
              { value: pieData5['15000-20000'], name: '15000-20000' },
              { value: pieData5['20000以上'], name: '20000以上' }
            ]"
          ></pie-charts>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import引入的组件需要注入到components中才能使用
import Breadcrumb from "../../../components/common/breadcrumb.vue";
import pieCharts from "../../home/children/pieCharts.vue";
import barCharts from "./barCharts.vue";
import { statistical } from "../../../service/manage.js";

export default {
  name: "userCharts",
  components: { Breadcrumb, pieCharts, barCharts },
  props: {},
  data() {
    return {
      BreadcrumbData: [
        { title: "首页", isLink: true, url: "/index" },
        { title: "组织", isLink: false },
        { title: "员工列表", isLink: false }
      ],
      loading: false,
      flag: true,
      companyId: this.$route.query.comId,
      // dataArr: [],
      pieData1: {},
      pieData2: {},
      barData3: {},
      barData4: {},
      pieData5: {}
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getStatistical();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  //方法集合
  methods: {
    async getStatistical() {
      this.loading = true;
      this.flag = false;
      const { data } = await statistical({ companyId: this.companyId });
      this.dataArr = [data];
      for (let i in data) {
        // console.log(data[i], i, ".........");
        if (i == "是否有客服经历") {
          this.pieData1 = data[i];
          // for (let val in data[i]){
          //   this.pieData1.push(data[i][val])
          // }
        }
        if (i == "之前从事过电商行业相关岗位") {
          this.pieData2 = data[i];
        }
        if (i == "哪些网购平台使用比较多") {
          this.barData3 = data[i];
        }
        if (i == "对哪个电商类目感兴趣/熟悉") {
          this.barData4 = data[i];
        }
        if (i == "您的期望薪酬范围") {
          this.pieData5 = data[i];
        }
      }
      // this.pieData1
      this.flag = true;
      this.loading = false;
    }
  }
};
</script>

<style lang="less" scoped>
.container {
  .line {
    display: flex;
    padding: 0 48px;
    margin-bottom: 80px;
    .chart {
      width: 50%;
    }
    .tit {
      display: inline-block;
      margin-bottom: 32px;
      color: #101010;
      font-size: 20px;
    }
    .pie {
      padding-left: 135px;
    }
  }
}
</style>
